.home {
  width: 100%;
  position: relative;
  padding-bottom: 50px;
  font-family: "IBM Plex Mono", monospace;
}
#about {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 150px;
}
#mywork {
  position: absolute;
  width: 100%;
  left: 0;
  top: 200vh;
  padding-bottom: 100px;
}
#contact {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100vh;
  padding-top: 80px;
}
footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  background-color: #abb6c2;
  color: black;
  font-size: 16px;
}
.face {
  width: 200px;
  border-radius: 50%;
}
#sticky {
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
}
.contactIcon {
  display: flex;
  justify-content: center;
  padding: 7px;
}
.contactIcon:hover {
  background-color: white;
  border-radius: 25px;
}
.icons{
  margin-top: 120px;
}
.iconsSmallScreen {
  margin-top: 50px;
}
.projectTitle {
  color: #ffc0cb;
  font-weight: bolder !important;
  font-size: 34px !important;
  text-align: center;  
  -webkit-text-stroke: 0.001px rgb(244, 144, 160);
}
.projectLinks {
  text-align: center;
}
.projectCard {
  box-shadow:
  inset 0 -3em 3em rgba(0,0,0,0.1),
        0 0  0 1px rgb(255,255,255),
        2.3em 0.3em 1em rgba(0,0,0,0.3);
}